import React, {Component} from 'react';
import { HashRouter,Switch, Route, Redirect } from 'react-router-dom';

import Dashboard from '../page/dashboard/Dashboard';
import Laporan from '../page/laporan/Laporan';
import Harga from '../page/harga/Harga';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        sessionStorage.getItem('loginAS') ? (
            <Component {...props} />
        ) : (
                <Redirect to={{
                    pathname: '/login',
                  state: { from: props.location }
                }} />
        
      )
    )} />
);


export default class Content extends Component {
	render() {
        return (
        	<section className="main-content">
        		<div className="main-content-inner">
        			<HashRouter>
                		<Switch>
                			<Route exact path='/' component={Laporan}></Route>
                            <Route exact path='/laporan' component={Laporan}></Route>
                            <Route exact path='/harga' component={Harga}></Route>
                        </Switch>
                	</HashRouter>
        		</div>
        	</section>
        )
    }
}