const moment = require('moment');

const urlFrontend=()=>{
	return "http://http://156.67.219.84:5090/";
}


const urlPrint=()=>{
	return "http://156.67.219.84:5050";
}

const getNow=()=>{
	return moment().format('YYYY-MM-DD');
}

const saveTanggal=(param)=>{
	var respon="";
	if(param){
		respon=moment(param).format('YYYY-MM-DD');
	}
	return respon;
}

const showTanggal=(param)=>{
	var respon="";
	if(param){
		respon=moment(param).format('DD/MM/YYYY');
	}
	return respon;
}

const showTanggalJam=(param)=>{
	var respon="";
	if(param){
		respon=moment(param).format('DD/MM/YYYY HH:mm:ss');
	}
	return respon;
}

const limitDefault =()=>{
	return 50
}

const urlBackend=()=>{
	return process.env.REACT_APP_API_URL
}

const exportTanggalDefault=(param)=>{
	var respon="";
	if(param){
		respon=moment(param).format('YYYY-MM-DD');
	}
	return respon;
}

const toAngka = (x)=>{
	var result = x;
	if(x){
		if(typeof x == "string" && x.indexOf(',') > -1){
			result = x.replace(/\,/g,'');
		}
	}else{
		result = 0;
	}
	return result;
}

const infoSession=(params)=>{
    var respon="";

    switch(params){
        case "id":
        	if(sessionStorage.getItem('loginAS')){
	        	var ses = sessionStorage.getItem('loginAS');
	        	var token=atob(ses);
	        	var ar = JSON.parse(token);
	        	respon=ar[0].id;
        	}
        break;

        case "nama":
        	if(sessionStorage.getItem('loginAS')){
	        	var ses = sessionStorage.getItem('loginAS');
	        	var token=atob(ses);
	        	var ar = JSON.parse(token);
	        	respon=ar[0].nama;
        	}
        break;

        case "alamat":
        	if(sessionStorage.getItem('loginAS')){
	        	var ses = sessionStorage.getItem('loginAS');
	        	var token=atob(ses);
	        	var ar = JSON.parse(token);
	        	respon=ar[0].alamat;
        	}
        break;
    }

    return respon;

}

const jsonToUpperCase=(data)=>{ 
	for(var i = 0; i < data.length; i++){ 
  		for (var key in data[i]) {
   			if(key.toUpperCase() !== key){
    			data[i][key.toUpperCase()] = data[i][key];
    			delete data[i][key];
   			}
  		}
 	}
 return data;  
}

const showTanggalHuruf=(data)=>{
	var data=new Date(data);
	var tahun = data.getFullYear();
	var bulan = data.getMonth();
	var tanggal = data.getDate();

	bulan=showBulan(bulan);
	return tanggal+" "+bulan+" "+tahun;
}

const showHari=(params)=>{
	switch(params){
		case 0:
			return "Minggu";
		break;

		case 1:
			return "Senin";
		break;

		case 2:
			return "Selasa";
		break;

		case 3:
			return "Rabu";
		break;

		case 4:
			return "Kamis";
		break;

		case 5:
			return "Jumat";
		break;

		case 6:
			return "Sabtu";
		break;
	}
}

const showBulan=(params)=>{
	switch(params){
		case 0:
			return "Januari"; 
		break;

		case 1:
			return "Februari";
		break;

		case 2:
			return "Maret";
		break;

		case 3:
			return "April";
		break;

		case 4:
			return "Mei";
		break;

		case 5:
			return "Juni";
		break;

		case 6:
			return "Juli";
		break;

		case 7:
			return "Agustus";
		break;

		case 8:
			return "September";
		break;

		case 9:
			return "Oktober";
		break;

		case 10:
			return "November";
		break;

		case 11:
			return "Desember";
		break;
	}
}


export{
	limitDefault,
	urlFrontend,
	urlBackend,
	exportTanggalDefault,
	toAngka,
	infoSession,
	jsonToUpperCase,
	getNow,
	saveTanggal,
	showTanggal,
	showTanggalJam,
	showTanggalHuruf,
	urlPrint,
}
