import React, { Component } from 'react';
import { Redirect, NavLink } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import API from '../../helpers/API';
import logo from "../../images/login/logo.png";
import logo_mini from "../../images/logo/logo.png";
import background_image from "../../images/login/patern01.png";
import background_box from "../../images/login/patern03.png";

import{
    toAngka,
} from '../../config/Utils';

var no_record=1;
class Daftar extends Component {
	 constructor(props) {
      super(props);
      this.state={
          isReady:false,
          redirect:false,
          xTipe:'T000001',
          xNama:'',
          xAlamat:'',
          xTelp:'',
          isLoading:true,
          dataTableHarga:[],
          dataTableTipe:[],
          dateCopyright:new Date().getFullYear(),


      }
      this._handleInputChange=this._handleInputChange.bind(this);
      this._handleInputChangeTipe=this._handleInputChangeTipe.bind(this);
    }

    _handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    _handleInputChangeTipe(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
         this._loadDataTableHarga(value);
    }

    _notif(type,title,message){
        swal({
            title: title,
            text: message,
            icon: type,
        });
    }

    _loading(status){
        this.setState({
            isLoading:status
        });
    }

    _loadDataTableHarga(params){
        this._loading(true);
        var filter={
            id:'',
            tipe:params
        }

        API.get('harga', {
            params: filter,
        }) .then(respon => {
            var ar=[];

            if(respon.data.data.length){
                ar=respon.data.data;
            }
            this.setState({
                dataTableHarga: ar,
                isLoading:false,
                isReady:true,
            });
        });
    }

    _loadDataTableTipe(){
    	//this._loading(true);
    	API.get('tipe-pelanggan').then(respon => {
    		var ar=[];
    		if(respon.data.data.length){
    			ar=respon.data.data;
    		}
    		this.setState({
    			dataTableTipe: ar,
    			isLoading:false,
    			isReady:true
    		})
    	});
    }

    _kirimPenawaran(){
    	var me=this;
    	var data=[];
    	var harga = document.getElementsByClassName('xInputHarga');
    	for (var i=0;i<harga.length;i++){
    		//console.log(harga[i].id);
    		data.push({
    			kode:harga[i].id,
    			nama_barang:harga[i].name,
    			harga:toAngka(harga[i].value)
    		});
    	}

    	var params={
    		tipe:this.state.xTipe,
    		nama:this.state.xNama,
    		alamat:this.state.xAlamat,
    		telp:this.state.xTelp,
    		harga:JSON.stringify(data)
    	};
    	API.post('penawaran',params).then(respon => {
    		if(!respon.data.success){
    			me._notif('error','Gagal', respon.data.message);
    		}else{
    			me._notif('sucess','Sukses', respon.data.message);
    		}
    	});



    }


    componentDidMount() {
        if(this.state.isLoading){
            this.setState({
                isReady:true,
                isLoading:false
            });
        }
    }

    render() {
    	if(!this.state.isReady){
            this.setState({
                isReady:true,
            });
            this._loadDataTableHarga(this.state.xTipe);
            this._loadDataTableTipe();
        }
    	return(
	    	<React.Fragment>
	    		<LoadingScreen
	    			loading={this.state.isLoading}
	    			bgColor='#f1f1f1'
	    			spinnerColor='#9ee5f8'
	    			textColor='#676767'
	    			logoSrc={logo}
	    			text=''
	    		>
	    		<div className="page-content fullPage">
	    			<div className="row">
	    				<div className="col-xs-12 col-md-12">
	    					<div className="alert alert-warning">
	    						<i className="ace-icon fa fa-bullhorn"> Silahkan lengkapi data dibawah, setelah itu klik tombol 'Kirim Penawaran' untuk melanjutkan proses pendaftaran. </i>
	    					</div>
	    				</div>
	    			</div>
	    			<div className="row">
                        <div className="col-xs-12 col-md-12">
                        	<div className="table-header">
	                			Profile
	                		</div>
	                		<div className="profile-user-info profile-user-info-striped" style={{width:"100%", margin:0}}>
	                			<div className="profile-info-row">
	                				<div className="profile-info-name"> Perusahaan/Perorangan </div>
									<div className="profile-info-value">
										<span>
											<select name="xTipe" className="form-control" value={this.state.xTipe} onChange={this._handleInputChangeTipe.bind(this)}>
												{
													this.state.dataTableTipe.map((item,index)=>{
			                                        	return(
			                                            	<React.Fragment key={"x_tipe_"+index}>
			                                            		<option value={item.id_tipe_pelanggan}>{item.tipe_pelanggan}</option>
			                                            	</React.Fragment>
			                                        	)
			                                    	})
												}
											</select>
										</span>
									</div>
								</div>
								<div className="profile-info-row">
	                				<div className="profile-info-name"> Nama </div>
									<div className="profile-info-value">
										<span>
											<input className="form-control" name="xNama" value={this.state.xNama} onChange={this._handleInputChange.bind(this)} />
										</span>
									</div>
								</div>
								<div className="profile-info-row">
	                				<div className="profile-info-name"> Alamat </div>
									<div className="profile-info-value">
										<span>
											<input className="form-control" name="xAlamat" value={this.state.xAlamat} onChange={this._handleInputChange.bind(this)} />
										</span>
									</div>
								</div>
								<div className="profile-info-row">
	                				<div className="profile-info-name"> No. Telp / HP </div>
									<div className="profile-info-value">
										<span>
											<input className="form-control" name="xTelp" value={this.state.xTelp} onChange={this._handleInputChange.bind(this)} />
										</span>
									</div>
								</div>
	                		</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                        	<div className="table-header">
	                			Penawaran Harga Laundry
	                		</div>
	                		<table id="table-harga" className="table table-striped table-bordered table-hover table-condensed">
	                			<thead>
	                				<tr>
					            		<th style={{width:"100px"}}></th>
					            		<th>Nama Barang</th>
					                    <th>Harga</th>
	                				</tr>
	                			</thead>
	                			<tbody>
	                			{
	                				this.state.dataTableHarga.map((item,index)=>{
                                        return(
                                            <React.Fragment key={"f_"+index}>
                                                <tr key={'row_'+index}>
                                                    <td>{no_record+index}</td>
                                                    <td>{item.kode+" - "+item.nama_barang}</td>
                                                    <td style={{textAlign:'right'}}><b><NumberFormat style={{textAlign:'right'}} className="form-control xInputHarga" id={item.kode} name={item.nama_barang} value={item.harga} thousandSeparator={true} prefix={''} decimalScale={2} /></b></td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
	                			}
	                			</tbody>
	                		</table>
                        </div>
                    </div>
                    <div className="row">
                    	<div className="col-xs-6 col-md-6">
                    		<NavLink className="btn btn-danger btn-round btn-flat btn-block right" to="/login" >Halaman Login</NavLink>
                    	</div>
                        <div className="col-xs-6 col-md-6">
                            <button className="btn btn-primary btn-round btn-flat btn-block right" onClick={this._kirimPenawaran.bind(this)} >Kirim Penawaran</button>
                        </div>
                    </div>
	    		</div>
	    		</LoadingScreen> 

	    	</React.Fragment>
    	)
    }

};

export default Daftar;