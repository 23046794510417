import React, {Component} from 'react';
import { NavLink, Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import LoadingScreen from 'react-loading-screen';
import { CSVLink } from "react-csv";
import moment from 'moment-timezone';
import API from '../../helpers/API';
import{
    limitDefault,
    urlPrint,
    //urlBackend,
    infoSession,
    toAngka,
    showTanggal,
    showTanggalJam,
    saveTanggal,
    //jsonToUpperCase
} from '../../config/Utils';
import logo from "../../images/logo/logo.png";

moment.tz.setDefault("Asia/Makassar");
var no_record=1;

class Laporan extends Component {
	constructor(props) {
        super(props);
        this.state={
            isReady:false,
            isLoading:false,

            dataTable:[],
            dataTableDept:[],
            dataCsv:[],
            offset:0,
            limit:1,
            info:"",
            currentPage:1,
            totalPage:0,
            dataTablePagging:[],
            total_tagihan:0,

            fTglAwal:moment(new Date()).format('YYYY-MM-DD'),
            fTglAkhir:moment(new Date()).format('YYYY-MM-DD'),
            fDept:''
        }
        this._handleInputChange=this._handleInputChange.bind(this);
    }

    _handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    _notif(type,title,message){
        swal({
            title: title,
            text: message,
            icon: type,
        });
    }

    _loading(status){
        this.setState({
            isLoading:status
        });
    }

    _clearFilter(){
        this.setState({
            fTglAwal:moment(new Date()).format('YYYY-MM-DD'),
            fTglAkhir:moment(new Date()).format('YYYY-MM-DD'),
            fDept:''
        })
    }

    _loadDataTable(offsetPage,limitPage){
    	this._loading(true);
    	var filter={
    		id:infoSession('id'),
            tgl_awal:this.state.fTglAwal,
            tgl_akhir:this.state.fTglAkhir,
            dept:this.state.fDept,
            offset:offsetPage,
            limit:limitPage
        };

        API.get('history-transaksi', {
            params: filter,
        }) .then(respon => {
        	var ar=[];
            var ar_csv=[];
            var ar_pagging=[];
            var total=0;
            var i=1;
            no_record=1;
            if(respon.data.data){
                if(respon.data.data.length){
                	ar=respon.data.data;
                    ar_csv=respon.data.data_csv;
                	//hit pagging
                    var jml_page=Math.ceil(parseInt(respon.data.total)/parseInt(this.state.limit));
                    for(var p=1;p<=jml_page;p++){
                        ar_pagging.push({
                            page:p
                        });
                    };
                }
            }
            this._setInfoFilter();
            this.setState({
            	dataTable: ar,
                dataCsv:ar_csv,
                dataTablePagging:ar_pagging,
                totalRecord:respon.data.total,
                total_tagihan:respon.data.total_tagihan,
                totalPage:jml_page,
                isLoading:false,
                isReady:true,
                offset:offsetPage,
                limit:limitPage,
                currentPage:offsetPage
            });
        });
    }

    _loadDataDept(){
    	API.get('dept').then(respon =>{
    		var ar=[];
    		 if(respon.data.data.length){
    		 	ar=respon.data.data;
    		 }
    		 this.setState({
    		 	dataTableDept:ar
    		 });
    	})
    }

    _createCsv(){
        /*this._loading(true);
        var filter={
            id:infoSession('id'),
            tgl_awal:this.state.fTglAwal,
            tgl_akhir:this.state.fTglAkhir,
            dept:this.state.fDept
        };

        API.get('csv-history-transaksi', {
            params: filter,
        }) .then(respon => {
            var ar=[];
            if(respon.data.data.length){
                ar=respon.data.data;
            }
            this.setState({
                dataCsv: ar,
                isLoading:false,
                isReady:true,
            });
             
        });*/
        document.getElementById('btn-export').click();
    }

    _clickInvoice(){
        window.open("http://156.67.219.84:5050/report/p_lap_invoice.php?tgl_awal="+saveTanggal(this.state.fTglAwal)+'&tgl_akhir='+saveTanggal(this.state.fTglAkhir)+'&pelanggan='+infoSession('id')+'&dept='+this.state.fDept+'&nama=', "_blank");
    }

    _clickRekap(){
        window.open("http://156.67.219.84:5050/report/p_rekap_order.php?tgl_awal="+saveTanggal(this.state.fTglAwal)+"&tgl_akhir="+saveTanggal(this.state.fTglAkhir)+"&pelanggan="+infoSession('id')+"&dept="+this.state.fDept+"&nama=", "_blank");
    }

    camelCase(str){
        return str.substring(0, 1).toUpperCase() + str.substring(1);
    };

  filterColumns(data){
    var me=this;
    // Get column names
    let headers = [];
    if(data[0]){
        const columns = Object.keys(data[0]);
        columns.forEach((col, idx) => {
          if (col !== "firstname") {
            // OR if (idx !== 0)
            headers.push({ label: me.camelCase(col), key: col });
          }
        });
    }
    return headers;
  };

    _clickFilter(){
        this._loadDataTable(1,limitDefault());
    }

    _clickReset(){
        this._clearFilter();
    }

    _setInfoFilter(){
        var infoMessage='Menampilkan semua data tanpa filter.';
        if(this.state.fTglAwal||this.state.fTglAkhir||this.state.fCabang||this.state.fDivisi||this.state.fKeterangan){
            infoMessage="Data ditampilkan berdasarkan filter ";
            if(this.state.fTglAwal){
                infoMessage=infoMessage+"Tanggal : '"+showTanggal(this.state.fTglAwal)+" - "+showTanggal(this.state.fTglAkhir) + "', ";
            }
        }
        this.setState({
            info:infoMessage
        })
    }

    _clickPreviousPage(){
        var page=this.state.currentPage;
        if(this.state.currentPage>1){
            page=page-1;
            this.setState({
                offset:page,
                currentPage:page
            });
            this._loadDataTable(page,this.state.limit);
        }
    }

    _clickNextPage(){
        var page=this.state.currentPage;
        if(this.state.totalPage > this.state.currentPage){
            page=page+1;
            this.setState({
                offset:page,
                currentPage:page
            });
            this._loadDataTable(page,this.state.limit);
        }
    }

    _clickPage(item){
        this.setState({
            currentPage:item
        });
        this._loadDataTable(item,this.state.limit);
    }

    componentDidMount() {
        if(this.state.isLoading){

            this.setState({
                isReady:true,
                isLoading:false,
            });
        }
    }

    render() {
        if(!this.state.isReady){
            var date = new Date();
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            //console.log(firstDay);
            this.setState({
                isReady:true,
                fTglAwal:moment(firstDay).format('YYYY-MM-DD')
            });
            this._loadDataDept();
            this._loadDataTable(this.state.offset,this.state.limit);
        }
        return(
        	<React.Fragment>
                <div className="breadcrumbs ace-save-state" id="breadcrumbs">
                    <ul className="breadcrumb">
                        <li>
                            <i className="ace-icon fa fa-home home-icon"></i>
                            <a href="#">Home</a>
                        </li>
                        <li className="active">Laporan</li>
                    </ul>
                </div>
                <div className="page-content fullPage">
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="search-area well well-sm">
                                <div className="form-horizontal">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-12 col-md-3 control-label no-padding-right">Dari Tanggal</label>
                                                <div className="col-xs-12 col-md-4">
                                                    <input type="date" className="form-control" name="fTglAwal" value={this.state.fTglAwal} onChange={this._handleInputChange.bind(this)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-xs-12 col-sm-12 col-md-3 control-label no-padding-right">Sampai Tanggal</label>
                                                <div className="col-xs-12 col-md-4">
                                                    <input type="date" className="form-control" name="fTglAkhir" value={this.state.fTglAkhir} onChange={this._handleInputChange.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-6">
                                            <div className="form-group">
                                                <label className="col-xs-12 col-md-3 control-label no-padding-right">Department</label>
                                                <div className="col-xs-12 col-md-9">
                                                    <select className="form-control" name="fDept" value={this.state.fDept} onChange={this._handleInputChange.bind(this)}>
                                                        <option value=''></option>
                                                        {
                                                            this.state.dataTableDept.map((item,index)=>{
                                                                return(
                                                                    <option value={item.id_dept}>{item.nama_dept}</option>
                                                                )
                                                            })
                                                        }
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-6 col-md-6">
                                            <button className="btn btn-success btn-white btn-bold btn-round" style={{marginRight:'5px'}} onClick={this._clickInvoice.bind(this)}>Invoice</button>
                                            <button className="btn btn-success btn-white btn-bold btn-round" style={{marginRight:'5px'}} onClick={this._clickRekap.bind(this)}>Rekap</button>
                                            {/*<button className="btn btn-success btn-white btn-bold btn-round" onClick={this._createCsv.bind(this)}>Download Excel</button>*/}
                                            <div hidden>
                                                <CSVLink id="btn-export" data={this.state.dataCsv} headers={this.filterColumns(this.state.dataCsv)} filename={"test.csv"}>
                                                    Download as CSV
                                                </CSVLink>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-md-6">
                                            <button className="btn btn-danger btn-white btn-bold btn-round pull-right" onClick={this._clickReset.bind(this)}>Reset</button>
                                            <button className="btn btn-primary btn-white btn-bold btn-round pull-right" onClick={this._clickFilter.bind(this)} style={{marginRight:'5px'}}>Filter</button>
                                        </div>
                                    </div>
                                </div>
                            </div>                   
                        </div>
                    </div>
                    <div className="row" style={{marginTop:10}}>
                    	<div className="col-xs-12 col-sm-12">
                    		<div className="table-header">
                                {this.state.info}
                            </div>
                            <table id="simple-table" className="table table-striped table-bordered table-hover no-padding">
                            	<thead>
                                	<tr>
                                		<th className="text-center">No</th>
                                        <th></th>
                                        <th className="text-center">No. Invoice</th>
                                        <th className="text-center">Tanggal</th>
                                        <th className="text-center">Department</th>
                                        <th className="text-center">Tagihan</th>
                                	</tr>
                                </thead>
                                <tbody>
                                {
                                	this.state.dataTable.map((item,index)=>{
                                		return(
                                			<React.Fragment key={"f_"+index}>
                                				<tr key={'row_'+index}>
                                                    <td>{no_record+index}</td>
                                                    <td className="center">
                                                        <div className="action-buttons">
                                                            <NavLink to="#" className="green bigger-140 show-details-btn" title="Show Details">
                                                                <i className="ace-icon fa fa-angle-double-down"></i>
                                                                <span className="sr-only">Details</span>
                                                            </NavLink>
                                                        </div>
                                                    </td>
                                                        <td>{item.no_bill.substring(4)}</td>
                                                        <td>{showTanggal(item.tanggal)}</td>
                                                        <td>{item.nama_dept}</td>
                                                        <td style={{textAlign:'right'}}><b><NumberFormat value={item.grand_total} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} /></b></td>
                                                </tr>
                                                <tr className="detail-row">
                                                    <td colSpan="12">
                                                    	<div className="table-detail">
                                                    		<div className="row">
                                                    			<div className="col-xs-12 col-sm-12">
                                                    				<table id="simple-table" className="table table-striped table-bordered table-hover no-padding">
                                                    					<thead>
                                                                            <tr>
                                                                                <th className="text-center" style={{width:'50px'}}>No</th>
                                                                                <th className="text-center" style={{width:'150px'}}>Nama Barang</th>
                                                                                <th className="text-center">Harga</th>
                                                                                <th className="text-center">Jumlah</th>
                                                                                <th className="text-center">Total</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        {
                                                                        	item.detail.map((record,index)=>{
                                                                        		return(
                                                                                    <React.Fragment key={"d_"+index}>
                                                                                        <tr key={'row_'+index}>
                                                                                            <td>{index+1}</td>
                                                                                            <td>{record.keterangan}</td>
                                                                                            <td style={{textAlign:'right'}}><NumberFormat value={record.harga} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} /></td>
                                                                                            <td style={{textAlign:'right'}}>{record.jumlah}</td>
                                                                                            <td style={{textAlign:'right'}}><NumberFormat value={record.total} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} /></td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                )
                                                                        	})
                                                                        }
                                                                        </tbody>
                                                    				</table>
                                                    			</div>
                                                    		</div>
                                                    	</div>
                                                    </td>
                                                </tr>
                                			</React.Fragment>
                                		)
                                	})
                                }
                                	<tr><td colSpan={5} style={{textAlign:'right'}}><b>Total Tagihan</b></td><td style={{textAlign:'right', backgroundColor:'#E5E5E5'}}><b><NumberFormat value={this.state.total_tagihan} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} /></b></td></tr>
                                	{/*<tr><td colSpan={6}></td></tr>
                                    <tr>
                                    	<td colSpan={5}>
                                    		<div className="col-xs-6">
                                            	<b>Jumlah Data : {this.state.totalRecord}</b>
                                        	</div>
                                    	</td>
                                    	<div className="col-xs-6 pull-right">
                                        		<div className="dataTables_paginate paging_simple_numbers" >
	                                                <ul className="pagination">
	                                                    <li className="paginate_button previous"><button type="button" className="btn btn-white btn-round btn-primary" onClick={this._clickPreviousPage.bind(this)}>{"<<"}</button></li>
	                                                    <li className="paginate_button">
	                                                        <div className="btn-group" style={{marginLeft:1}}>
	                                                        <button data-toggle="dropdown" className="btn btn-primary btn-white btn-round dropdown-toggle">{this.state.currentPage} <i className="ace-icon fa fa-angle-down icon-on-right"></i></button>
	                                                        <ul className="dropdown-menu">
	                                                        {
	                                                            this.state.dataTablePagging.map((item, index)=>{
	                                                                var batas="";
	                                                                if(index>0){
	                                                                    batas=<li className='divider' key={'divider_'+index}></li>;
	                                                                }
	                                                                return(
	                                                                    <div key={'li_'+index}>
	                                                                        {batas}
	                                                                        <li style={{textAlign:'center'}} onClick={()=>{
	                                                                            this._clickPage(item.page);
	                                                                        }}>{item.page}</li>
	                                                                    </div>
	                                                                )
	                                                            })
	                                                        }
	                                                        </ul>
	                                                        </div>
	                                                    </li>
	                                                    <li className="paginate_button next "><button type="button" className="btn btn-white btn-round btn-primary" onClick={this._clickNextPage.bind(this)}>{">>"}</button></li>
	                                                </ul>
                                            	</div>
                                        	</div>
                                    </tr>*/}

                                </tbody>
                            </table>
                    	</div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default Laporan;