import React, {Component} from 'react';
import { Link, NavLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import moment from 'moment-timezone';
//var moment = require('moment');
moment.tz.setDefault("Asia/Makassar");

class Dashboard extends Component {
	constructor(props) {
        super(props);
        this.state={
            isReady:false,
            isLoading:false,
            offset:0,
            limit:1000
        }
    }

    componentDidMount() {
        if(this.state.isLoading){
            this.setState({
                isReady:true,
                isLoading:false
            });
        }
    }

    render() {
        return(
        	<React.Fragment>
                <div className="breadcrumbs ace-save-state" id="breadcrumbs">
                    <ul className="breadcrumb">
                        <li>
                            <i className="ace-icon fa fa-home home-icon"></i>
                            <a href="#">Home</a>
                        </li>
                        <li className="active">Dashboard</li>
                    </ul>
                </div>
                <div className="page-content fullPage">
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="infobox infobox-blue infobox-dark">
                                <div className="infobox-chart">
                                    <i className="ace-icon fa fa-signal"></i>
                                </div>
                                <div className="infobox-data">
                                    <span className="infobox-data-number">6,251</span>
                                    <div className="infobox-content">pageviews</div>
                                </div>

                            </div>                   
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default Dashboard;