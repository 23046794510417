import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state={
            isReady:false,
            dateCopyright:new Date().getFullYear(),
            
        }
    }

    componentDidMount() {
        if(!this.state.isReady){
            this.setState({
                isReady:true
            });
        }
    }

    render() {
        return(
                        <React.Fragment>
                            <section className="footer">
                                <div className="footer-inner">
                                    <div className="footer-content" style={{borderTop:'10px'}}>
                                        <span className="bigger-90 cetak">
                                            <span className="blue">                        </span>
                                            &copy; Alam Sari Laundry {this.state.dateCopyright}
                                        </span>
                                    </div>
                                </div>
                            </section>
                        </React.Fragment>
                   
        )
    }

}

export default Footer;