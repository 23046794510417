import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {
    infoSession
} from '../config/Utils';
import logo from "../images/logo/logo.png";

class Header extends Component {
    constructor(props) {
        super(props);
        this.state={
            isReady:false,
            isLogout:false,
            nama:infoSession('nama')
        }
    }

    _logout(status){
        if(status){
            sessionStorage.setItem('loginAS','');
            sessionStorage.clear();
            this.setState({
                isLogout:true
            });
            window.location.reload(true);
        }
    }

    componentDidMount() {
        if(!this.state.isReady){
            this.setState({
                isReady:true
            });
        }
    }

    render() {
        return(
                            <React.Fragment>
                                <div id="navbar" className="navbar navbar-default          ace-save-state">
                                    <div className="navbar-container ace-save-state" id="navbar-container">
                                        <button type="button" className="navbar-toggle menu-toggler pull-left" id="menu-toggler" data-target="#sidebar">
					                        <span className="sr-only">Toggle sidebar</span>
					                        <span className="icon-bar"></span>
					                        <span className="icon-bar"></span>
					                        <span className="icon-bar"></span>
				                        </button>
                                        <div className="navbar-header pull-left">
					                        <a href="#" className="navbar-brand">
						                        <small>
                                                <img src={logo} style={{height:20}} className="msg-photo" alt="" />
							                        <span style={{marginLeft:"10px"}}><b>Alam Sari</b> Laundry</span>
						                        </small>
					                        </a>
				                        </div>
                                        <div className="navbar-buttons navbar-header pull-right" role="navigation">
                                            <ul className="nav ace-nav">
                                                <li className="light-blue dropdown">
                                                    <a data-toggle="dropdown" href="#" className="dropdown-toggle">
                                                        <img className="nav-user-photo" src="assets/images/avatars/avatar2.png" alt="" />
                                                        <span className="user-info">
                                                            <small>{this.state.nama}</small>
                                                            
                                                        </span>

                                                        <i className="ace-icon fa fa-caret-down"></i>
                                                    </a>
                                                    <ul className="user-menu dropdown-menu-right dropdown-menu dropdown-yellow dropdown-caret dropdown-close">
                                                        
                                                        <li>
                                                            <a href="#" onClick={()=>{
                                                                this._logout(true);
                                                            }}>
                                                                <i className="ace-icon fa fa-power-off"></i>
                                                                Logout
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                         </div>
                                    </div>
                                </div>
                            </React.Fragment>
        )
    }

}
export default Header;