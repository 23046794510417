import React, { Component } from 'react';
import { Redirect, NavLink, Link } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import swal from 'sweetalert';
import API from '../../helpers/API';
import logo from "../../images/login/logo.png";
import logo_mini from "../../images/logo/logo.png";
import background_image from "../../images/login/patern01.png";
import background_box from "../../images/login/patern03.png";
//import video1 from "../../video/video1.mp4";

class Login extends Component {
    constructor(props) {
      super(props);
      this.state={
          isReady:false,
          redirect:false,
          xPin:'',
          isLoading:true,
          dateCopyright:new Date().getFullYear(),
      }
      this._handleInputChange=this._handleInputChange.bind(this);
    }

    _handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    _notif(type,title,message){
        swal({
            title: title,
            text: message,
            icon: type,
        });
    }

    _loading(status){
        this.setState({
            isLoading:status
        });
    }

    /*_callBack(params){
        if(params){
            if(params.token){
                var temp=[];
                sessionStorage.setItem('loginGoaml2020',params.token);
                window.location.reload(true);
            }
        }
    }*/

    _submitLogin(){
        var me=this;

        if(!this.state.xPin){
            me._notif('error','Gagal', 'PIN tidak boleh kosong.');
            return false;
        };

        var params= {
            pin:this.state.xPin.trim()
        };
        API.post('login',params).then(respon => {
          if(!respon.data.success){
            //me._showMessage('error', 'Gagal terhubung dengan webservice');
            me._notif('error','Gagal', respon.data.message);
          }else{
            me._notif('success', 'Sukses', respon.data.message);
            sessionStorage.setItem('loginAS',respon.data.token);
           // sessionStorage.setItem('defaultConfigPanjar', JSON.stringify(respon.data.config));
            window.location.reload(true);
          }

        }).catch(e => {
            console.log(e);
        });
    }

    componentDidMount() {
        if(this.state.isLoading){
            this.setState({
                isReady:true,
                isLoading:false
            });
        
        
    }
}

    render() {
        if(sessionStorage.getItem('loginAS')){
            return(
                <Redirect to={{
                    pathname: '/',
                }} />
            )
        }
        return(
            
                            <React.Fragment>
                                <LoadingScreen
                                    loading={this.state.isLoading}
                                    bgColor='#f1f1f1'
                                    spinnerColor='#9ee5f8'
                                    textColor='#676767'
                                    logoSrc={logo}
                                    text=''
                                > 
		                            <body className="hold-transition login-page bg-login">
                                        

                                        <div className="login-box">
                                            <div className="login-box-body" style={{border:"solid 2px #cfcfcf", textAlign:"center", backgroundRepeat:"repeat-x"}}>
                                                <div id="divInfo" style={{textAlign:"center", borderBottom:"solid 1px #88b774", paddingBottom:"5px", marginBottom:"10px"}} >
                                                    <img width="80" src={logo}  /> <br /> <br />
                                                    {/*<span style={{color:"#267207", lineHeight:"none", fontSize:"20px"}}>
                                                        Aplikasi <b>GOAML</b> <br />
                                                    </span>*/}
                                                </div>
                                                <span style={{textAlign:"center", fontSize:"13px"}}>Alam Sari Laundry {'\u00A9'} {this.state.dateCopyright}</span>
                                                <div className="login-logo"></div>
                                                <div className="input-group">
                                                    <span className="input-group-addon" style={{border:"solid 1px", borderColor:"#d2d6de", borderRight:'none'}}><i className="fa fa-lock"></i></span>
                                                    <input type="password" name="xPin" value={this.state.xPin} onChange={this._handleInputChange.bind(this)} onKeyPress={(e)=>{
                                                        if(e.key=='Enter'){
                                                            document.getElementById('btn-login').click();
                                                        }
                                                    }} style={{background:"#FAFFB01", border:"1px solid", borderColor:"#d2d6de"}} className="form-control" placeholder="Masukan PIN"></input>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        {/*<button id="btn-register" className="btn btn-primary btn-block btn-flat" >Daftar Sebagai Mitra</button>*/}
                                                        <NavLink id="btn-register" to="/register" className="btn btn-primary btn-block btn-flat">Daftar sebagai Mitra</NavLink>
                                                    </div>
                                                    <div className="col-xs-4">
                                                        <button id="btn-login" className="btn btn-success btn-block btn-flat" onClick={()=>{this._submitLogin()}} >Login</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </body>
                                    </LoadingScreen>
                            </React.Fragment>
        )
    }
}
export default Login;