import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Header from './Header';
import Menu from './Menu';
import Content from './Content';
import Footer from './Footer';

class DefaultLayout extends Component {

	render() {
    	return (
    		<div>
        		<Header />
        		<Menu />
        		<Content />
				<Footer />
      		</div>
    	)
    }
}

export default DefaultLayout;