import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.state={
            isReady:false,
            isLoading:true,
        }
    }

    componentDidMount() {
        if(this.state.isLoading){
            this.setState({
                isReady:true,
                isLoading:false
            });
        }
    }

    render() {
        return(
        	<React.Fragment>
        		<section id="sidebar" className="sidebar                  responsive                    ace-save-state">
        			<ul className="nav nav-list">
        				{/*<li className="">
                            <NavLink to='/'>
                                <i className="menu-icon fa fa-bookmark red"></i>
                                <span className="menu-text"> Dashboard </span>
                            </NavLink>
                            <b className="arrow"></b>
                        </li>*/}
                        <li className="">
                            <NavLink to='/laporan'>
                                <i className="menu-icon fa fa-list-alt"></i>
                                <span className="menu-text">Laporan Laundry</span>
                            </NavLink>
                        </li>
                        <li className="">
                            <NavLink to='/harga'>
                                <i className="menu-icon fa fa-dollar"></i>
                                <span className="menu-text">Daftar Harga</span>
                            </NavLink>
                        </li>
                       
        			</ul>
        		</section>
        	</React.Fragment>
        )
    }


}
export default Menu;