import React, { Component } from 'react';
import { HashRouter,Switch, Route, Redirect } from 'react-router-dom';
import DefaultLayout from "./layout/DefaultLayout";
import Login from "./page/login/Login";
import Daftar from "./page/daftar/Daftar";

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        sessionStorage.getItem('loginAS') ? (
            <Component {...props} />
        ) : (
                <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
        
      )
    )} />
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/login" name="Login Page" component={Login} />
          <Route path="/register" name="Daftar Sebagia Mitra" component={Daftar} />
          <PrivateRoute path="/" name="Home" component={DefaultLayout} />
        </Switch>
      </HashRouter>
    );
  }
}

export default App;