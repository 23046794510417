import React, {Component} from 'react';
import { NavLink, Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';
import swal from 'sweetalert';
import LoadingScreen from 'react-loading-screen';
import { CSVLink } from "react-csv";
import moment from 'moment-timezone';
import API from '../../helpers/API';
import{
    limitDefault,
    urlPrint,
    //urlBackend,
    infoSession,
    toAngka,
    showTanggal,
    showTanggalJam
    //jsonToUpperCase
} from '../../config/Utils';
import logo from "../../images/logo/logo.png";

moment.tz.setDefault("Asia/Makassar");
var no_record=1;

class Harga extends Component {
    constructor(props) {
        super(props);
        this.state={
            isReady:false,
            isLoading:false,

            dataTable:[],
            fNamaBarang:''
        }
        this._handleInputChange=this._handleInputChange.bind(this);
    }

    _handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    _notif(type,title,message){
        swal({
            title: title,
            text: message,
            icon: type,
        });
    }

    _loading(status){
        this.setState({
            isLoading:status
        });
    }

    _loadDataTable(){
        this._loading(true);
        var filter={
            id:infoSession('id'),
            nama_barang:this.state.fNamaBarang
        }

        API.get('harga-pelanggan', {
            params: filter,
        }) .then(respon => {
            var ar=[];

            if(respon.data.data.length){
                ar=respon.data.data;
            }
            this.setState({
                dataTable: ar,
                isLoading:false,
                isReady:true,
            });
        });
    }

    _clickFilter(){
        this._loadDataTable();
    }

    _clickReset(){
        this.setState({
            fNamaBarang:'',
            isReady:false
        });
    }

    componentDidMount() {
        if(this.state.isLoading){

            this.setState({
                isReady:true,
                isLoading:false,
            });
        }
    }

    render() {
        if(!this.state.isReady){
            this.setState({
                isReady:true,
            });
            this._loadDataTable();
        }

        return(
            <React.Fragment>
                <div className="breadcrumbs ace-save-state" id="breadcrumbs">
                    <ul className="breadcrumb">
                        <li>
                            <i className="ace-icon fa fa-home home-icon"></i>
                            <a href="#">Home</a>
                        </li>
                        <li className="active">Harga</li>
                    </ul>
                </div>
                <div className="page-content fullPage">
                    <div className="row">
                        <div className="col-xs-12 col-md-12">
                            <div className="input-group">
                                <input className="form-control" name="fNamaBarang" value={this.state.fNamaBarang} placeholder="Filter data berdasarkan kode atau nama barang" onChange={this._handleInputChange.bind(this)}></input>
                                <span className="input-group-btn">
                                    <button className="btn btn-sm btn-info no-radius" type="button" onClick={this._clickFilter.bind(this)}>Filter</button>
                                    <button className="btn btn-sm btn-danger no-radius" type="button" onClick={this._clickReset.bind(this)}>Reset</button>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{marginTop:'10px'}}>
                        <div className="col-xs-12 col-md-12">
                            <table id="simple-table" className="table table-striped table-bordered table-hover no-padding">
                                <thead>
                                    <tr>
                                        <th className="text-center" style={{width:'50px'}}>No</th>
                                        <th className="text-center">Nama Barang</th>
                                        <th className="text-center">Harga</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.dataTable.map((item,index)=>{
                                        return(
                                            <React.Fragment key={"f_"+index}>
                                                <tr key={'row_'+index}>
                                                    <td>{no_record+index}</td>
                                                    <td>{item.kode+" - "+item.nama_barang}</td>
                                                    <td style={{textAlign:'right'}}><b><NumberFormat value={item.harga} displayType={'text'} thousandSeparator={true} prefix={''} decimalScale={2} /></b></td>
                                                </tr>
                                            </React.Fragment>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )

    }


};
export default Harga;